@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

html {
  scroll-behavior: smooth;
}
